import React, { Dispatch, useEffect } from "react";
import { connect } from "react-redux";
import { BookingData, ConfirmData } from "../../utils/policyTypes";
import * as actionTypes from '../../redux/actionTypes/generalActionTypes';
import { confirmPolicy, hideModal } from "../../redux/actionCreators/generalActionCreators";
import { User } from "../../redux/types";
import SimpleModal from "../General/SimpleModal";
import { useHistory } from "react-router";

interface Props {
    triggerConfirm: (confirmData: ConfirmData) => void;
    closeModal(): void;
    logout(token: string): void;
    showModal: boolean;
    description: string;
    header: string;
    nextStep: string;
    history: History;
    isLoading: boolean;
    dataToConfirm: BookingData | null,
    isConfirmed: boolean,
    user: User | null,
    erroredConfirm: null | Error | string
}

const SuccessfulPayment = (props: Props) => {
    let history = useHistory();

    //confirm policy with data to confirm
    useEffect(() => {
        if (props.dataToConfirm === null) {
            history.push('/');
        }

        const customerId = props.user !== null ? props.user.customerId : null;

        if (props.dataToConfirm !== null && !props.isConfirmed && !props.isLoading) {
            const data: ConfirmData = { customerId: customerId, policyId: props.dataToConfirm.policyId, bankOrderId: props.dataToConfirm.bankOrderId, policyNumber: props.dataToConfirm.policyNumber, lifePolicy: props.dataToConfirm.lifePolicy };
            props.triggerConfirm(data);
        }
    }, []);

    const handleCloseModal = () => {
        props.closeModal();
    };

    return (
        <div>
            <SimpleModal show={props.showModal} header={props.header} text={props.description} onClose={handleCloseModal} onHide={() => { return; }} />

            <div className="card card-custom overflow-hidden">
                <div className="card-body p-0">
                    <div className="row justify-content-center bgi-size-cover bgi-no-repeat py-8 px-8 py-md-27 px-md-0" style={{ backgroundImage: "url(../media/bg/bg-8.jpg)" }}>
                        <div className="col-md-9">
                            <div className="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row">
                                <h1 className="display-4 text-white font-weight-boldest mb-10">
                                    Pagesa eshte kryer me sukses.

                                </h1>
                            </div>
                            <div className="border-bottom w-100 opacity-20"></div>
                            {props.isLoading && <>
                                <div className="d-flex align-items-center">
                                    <div className="display-4 text-white">Ju lutem prisni...
                                        <br />
                                        Jemi duke konfirmuar policen ne sistemin tone...</div>
                                    <div className="spinner spinner-white spinner-lg mr-10"></div>
                                </div></>
                            }
                            {!props.isLoading && props.isConfirmed &&
                                <>
                                    <div className="d-flex align-items-center">
                                        <div className="display-4 text-white">
                                            Polica eshte konfirmuar.<br />
                                            Tashme e keni blere policen me sukses!   <br />  <br />
                                            Polica eshte derguar ne email-in juaj. Ju lutem kontrolloni edhe ne SPAM folderin te emailit te juaj.  <br /><br />
                                            Klikoni<a href='../policy-search' > ketu </a> per te kerkuar dhe pare policen.
                                        </div>
                                    </div>
                                </>
                            }
                            {props.erroredConfirm !== "" && props.erroredConfirm !== undefined &&
                                <>
                                    <div className="d-flex align-items-center">
                                        <div className="display-4 text-white">{props.erroredConfirm}
                                            <br />  <br />  <br />
                                            Kontakte   <br />
                                            info@uniqa-ks.com         <br />
                                            +381 38 240 241           <br />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch: Dispatch<actionTypes.ConfirmPolicyAction | actionTypes.HideModalAction>) => ({
    triggerConfirm: (confirmData: ConfirmData) => {
        dispatch(confirmPolicy(confirmData));
    },
    closeModal: () => { dispatch(hideModal()); },
    // logout: () => { dispatch(logoutUser()); }
});

const mapStateToProps = (state: any) => ({
    showModal: state.general.showModal,
    header: state.general.header,
    description: state.general.text,
    nextStep: state.general.nextStep,
    isLoading: state.isLoading[actionTypes.CONFIRM_POLICY],
    user: state.auth.user,
    isConfirmed: state.general.isConfirmed,
    dataToConfirm: state.policy.bookingData,
    erroredConfirm: state.error[actionTypes.CONFIRM_POLICY]
});

export default connect(mapStateToProps, mapDispatchToProps)(SuccessfulPayment);