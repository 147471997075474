import React, { useEffect } from "react";
import { useFormik, FormikProps } from "formik";
import { connect, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Dispatch } from "redux";
import { RequestPasswordAction } from "../../redux/actionTypes/authActionTypes";
import { logoutUser, requestPassword } from "../../redux/actionCreators/authActionCreators";
import { hideModal } from "../../redux/actionCreators/generalActionCreators";
import { AppState } from "../../redux/reducers/rootReducer";
import SimpleModal from "../General/SimpleModal";
import { HideModalAction } from "../../redux/actionTypes/generalActionTypes";
import * as actionTypes from "../../redux/actionTypes/authActionTypes";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { User } from "../../redux/types";

interface FormValues {
  email: string;
}

const initialValues = {
  email: "",
};

interface Props {
  onRequestPassword(email: string): void;
  closeModal(): void;
  logout(token: string): void;
}

const ForgotPassword = (props: FormikProps<FormValues> & Props) => {

  const error = useSelector<AppState, Error | string | null>((state: any) => state.error[actionTypes.REQUEST_PASSWORD]);
  const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[actionTypes.REQUEST_PASSWORD]);
  const showModal = useSelector<AppState, boolean>((state: any) => state.general.showModal);
  const header = useSelector<AppState, string>((state: any) => state.general.header);
  const text = useSelector<AppState, string>((state: any) => state.general.text);
  const user = useSelector<AppState, User | null>((state: any) => state.auth.user);
  let history = useHistory();

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Format I gabuar I email-it")
      .min(3, "Minimumi 3 simbole")
      .max(50, "Maksimumi 50 simbole")
      .required("E domosdoshme"),
  });

  useEffect(() => {
    if (!!user) props.logout(user!.token);
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values) => {
      props.onRequestPassword(values.email);
    }
  });

  const emailClass = 'form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0 '
    + (formik.touched.email && ((formik.errors.email && 'is-invalid') || (!formik.errors.email && 'is-valid')));

  const handleCloseModal = () => {
    props.closeModal();
    history.push('/auth/login')
  };

  return (

    <>
      <div className="d-flex flex-column flex-root">
        <div className="login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid" id="kt_login">
          <>
            <div className="login-container order-2 order-lg-1 d-flex flex-center flex-row-fluid px-7 pt-lg-0 pb-lg-0 pt-4 pb-6 bg-white">
              <div className="login-content d-flex flex-column pt-lg-0 pt-12">
                <Link to="/" className="login-logo pb-xl-20 pb-15">
                  <img
                    alt="Logo"
                    className="max-h-70px"
                    src={toAbsoluteUrl("/media/logos/sigal.png")}
                  />
                </Link>
                <div className="login-form" id="kt_login_signin_form">
                  <div className="text-center mb-10 mb-lg-20">
                    <h3 className="font-size-h1">Harruat fjalëkalimin ?</h3>
                    <Link to="/auth/login" className="text-primary font-weight-bolder"> Kthehu ne Login </Link>
                    <div className="text-muted font-weight-bold">
                      Vendoseni emailin dhe rivendosni fjalekalimin
                    </div>
                  </div>
                  <form
                    onSubmit={formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                  >
                    {error && error !== "" && <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                      <div className="alert-text font-weight-bold">{error}</div>
                    </div>}

                    <div className="form-group fv-plugins-icon-container">
                      <label className="font-size-h6 font-weight-bolder text-dark">Email</label>
                      <input
                        placeholder="Email"
                        type="email"
                        className={emailClass}
                        {...formik.getFieldProps("email")}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{formik.errors.email}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="pb-lg-0 pb-5">
                      <button
                        id="kt_login_singin_form_submit_button"
                        type="submit"
                        disabled={isLoading}
                        className={`btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3`}
                      >
                        <span>Konfirmoj</span>
                        {isLoading && <span className="ml-3 spinner spinner-white"></span>}
                      </button>
                    </div>
                  </form>
                  {/*end::Form*/}
                </div>
              </div>
              <SimpleModal show={showModal} header={header} text={text} onClose={handleCloseModal} onHide={() => { return; }} />
            </div>
          </>
          {/*begin::Aside*/}
          <div className="login-aside order-1 order-lg-2 bgi-no-repeat bgi-position-x-right">
            <div className="login-conteiner bgi-no-repeat bgi-position-x-right bgi-position-y-bottom">
              {/*begin::Aside title*/}
              <h3 className="pt-lg-40 pl-lg-20 pb-lg-0 pl-10 py-20 m-0 d-flex justify-content-lg-start font-weight-boldest display5 display1-lg text-white">
                Ju krijoni familje<br />
                Ne e sigurojmë atë
              </h3>
              {/*end::Aside title*/}
            </div>
          </div>
        </div>
        {/*end::Aside*/}
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch: Dispatch<RequestPasswordAction | HideModalAction  | actionTypes.LogoutUserAction>) => ({
  onRequestPassword: (email: string) => {
    dispatch(requestPassword(email));
  },
  closeModal: () => { dispatch(hideModal()); },
  logout: (token: string) => { dispatch(logoutUser(token)); }
});

export default (connect(null, mapDispatchToProps)(ForgotPassword));
