import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import ApiClient from "../../../infrastructure/ApiClient";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Loading2 } from '../../Layout/Loading2';

const ConfirmEmail = () => {
    const [loading, setLoading] = React.useState(false);
    const [isSuccess, setSuccess] = React.useState(false);
    const [alreadyConfirmed, setAlreadyConfirmed] = React.useState(false);
    const [error, setError] = React.useState('');
    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');

    useEffect(() => {
        if (token !== null && token !== "") {
            triggerConfirm(token);
        }
    }, []);

    const triggerConfirm = async (token: string) => {
        setLoading(true);

        try {
            const { data: response } = await ApiClient.get('/api/user/confirmEmail', { token: token });

            if (response.succeeded) {
                if (response.action !== '' && response.action === '1')
                    setAlreadyConfirmed(true);
                else setSuccess(true);
            }
            else {
                setError(response.errorMessage);
            }
        } catch (error: any) {
            setError(error.message);
        }
        setLoading(false);
    }

    return (
        <div className="d-flex flex-column flex-root">
            <div className="login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid" id="kt_login">
                <div className="login-container order-2 order-lg-1 d-flex flex-center flex-row-fluid px-7 pt-lg-0 pb-lg-0 pt-4 pb-6 bg-white">
                    <div className="d-flex flex-column pt-lg-0 pt-12">
                        <Link to="/" className="login-logo pb-xl-20 pb-15">
                            <img
                                alt="Logo"
                                className="max-h-70px"
                                src={toAbsoluteUrl("/media/logos/sigal.png")}
                            />
                        </Link>
                        <div className="login-form" id="kt_login_signin_form">
                            <form>

                                <div className="pb-5 pb-lg-15">
                                    {loading
                                        ? <h3 className="font-size-h1"><Loading2 /></h3>
                                        : <>
                                            {isSuccess && <h3 className="font-size-h1">
                                                Email-i është konfirmuar me sukses. Ju lutemi klikoni<Link to="/auth/login" className="text-primary font-weight-bolder text-hover-primary">këtu</Link> për t'u identifikuar.
                                            </h3>}
                                            {error && error !== null && error !== '' && <>
                                                <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
                                                    Konfirmimi i emailit dështoi. Ju lutemi klikoni<Link to="/auth/login" className="text-primary font-weight-bolder text-hover-primary">këtu</Link> për t'u identifikuar
                                                </h3>
                                                <h3 className="font-size-h1">
                                                    {error}
                                                </h3>
                                            </>}
                                            {alreadyConfirmed && <h3 className="font-size-h1">
                                                Llogaria është aktivizuar tashmë. Ju lutemi klikoni<Link to="/auth/login" className="text-primary font-weight-bolder text-hover-primary">këtu</Link> për t'u identifikuar.
                                            </h3>}
                                        </>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/*begin::Aside*/}
                <div className="login-aside order-1 order-lg-2 bgi-no-repeat bgi-position-x-right">
                    <div className="login-conteiner bgi-no-repeat bgi-position-x-right bgi-position-y-bottom">
                        {/*begin::Aside title*/}
                        <h3 className="pt-lg-40 pl-lg-20 pb-lg-0 pl-10 py-20 m-0 d-flex justify-content-lg-start font-weight-boldest display5 display1-lg text-white">
                            Ju krijoni familje<br />
                            Ne e sigurojmë atë
                        </h3>
                        {/*end::Aside title*/}
                    </div>
                </div>
            </div>
            {/*end::Aside*/}
        </div>
    )
}


export default connect()(ConfirmEmail);