import React from "react";
import { Link } from "react-router-dom";
import { useFormikWizard } from 'formik-wizard';
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import Nav from './Nav';
import CheckboxField from '../../Custom/CheckboxField';
import InputField from "../../Custom/InputField";
import CaptchaGenerator from "../../Custom/CaptchaGenerator";
import { useFormikContext } from "formik";
import moment from 'moment';

const Info = () => {
  const { values }: any = useFormikWizard();
  const {
    setFieldValue,
  } = useFormikContext();

  const result = (text: string) => {
    setFieldValue('captchaGenerated', text);
  }

  return (
    <>
      <div className="d-flex flex-column-auto flex-column px-10">
        <Link to="/" className="login-logo pb-lg-4 pb-10">
          <img
            alt="Logo"
            className="max-h-70px"
            src={toAbsoluteUrl("/media/logos/sigal.png")}
          />
        </Link>
        {/* <div className="d-flex flex-column-auto flex-column px-10" id="kt_login_signin_form"> */}
        <Nav step={2} />
        {/* </div> */}
      </div>
      <div className="login-form form px-10 fv-plugins-bootstrap fv-plugins-framework">
        <div className="pb-10 pb-lg-15">
          <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">Verifiko</h3>
          <div className="text-muted font-weight-bold font-size-h4">
          Konfirmo te dhenat dhe regjistrohu
                    </div>
        </div>
        <h4 className="font-weight-bolder mb-3">
        Detajet e llogarisë:
                        </h4>
        <div className="text-dark-50 font-weight-bold line-height-lg mb-8">
          <div>{values.account.clientno}{values.account.nipt}</div>
          <div>{values.account.title} {values.account.lastName}</div>
          <div>{values.account.sex === "1" ? 'Mashkull' : values.account.sex === "2" ? 'Femer' : ""}</div>
          <div>{values.account.birthdate ? moment(values.account.birthdate).format('DD/MM/YYYY') : ""}
              </div>
        </div>

        <h4 className="font-weight-bolder mb-3">
          Detajet e kontaktit:
                        </h4>
        <div className="text-dark-50 font-weight-bold line-height-lg mb-8">
          <div>{values.contact.address}</div>
          <div>{values.contact.place}</div>
          <div>{values.contact.mob}</div>
          <div>{values.contact.email}</div>
        </div>

        <div className="form-group">
            <CheckboxField name="acceptTerms" type="checkbox" label="I pranoj Kushtet dhe termat per mbrojtjen e te dhenave" />
        </div>
        <div className="form-group">
          <CaptchaGenerator result={result} />
          <InputField name="captchaInputted" type="text" placeholder="Shkruaj kodim këtu" label="Kodi i sigurise" />
        </div>
      </div>
    </>
  );
}

export default Info;