import React from 'react';
import { Link } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { Notification, User } from '../redux/types';
import { AppState } from '../redux/reducers/rootReducer';
import { markAppealAsRead, readFirstLevelAppeal, readFirstLevelAppealReset, readSecondLevelAppeal, readSecondLevelAppealReset } from '../redux/actionCreators/healthPreClaimActionCreators';
import * as healthClaimActionTypes from '../redux/actionTypes/healthClaimActionTypes';
import * as generalActionTypes from '../redux/actionTypes/generalActionTypes';
import * as authActionTypes from '../redux/actionTypes/authActionTypes';
import { hideModal } from '../redux/actionCreators/generalActionCreators';
import { getCustomer } from '../redux/actionCreators/authActionCreators';
import { GetCustomerAction } from '../redux/actionTypes/authActionTypes';
import ReadFirstLevelAppealModal from './DamageClaim/Grid/HealthClaim/ReadFirstLevelAppealModal';
import ReadSecondLevelAppealModal from './DamageClaim/Grid/HealthClaim/ReadSecondLevelAppealModal';
import { Loading } from './Layout/Loading';

interface Props {
    onRead: (code: string) => void;
    readFirstLevelAppealReset(): void;
    onGetCustomer(customerId: string): void;
    triggerMark(code: string): void;
    onReadSecondLevel: (code: string) => void;
    readSecondLevelAppealReset(): void;
}

const Home = (props: Props) => {
    const user = useSelector<AppState, User | null>((state: any) => state.auth.user);
    const header = useSelector<AppState, string>((state: any) => state.general.header);
    const text = useSelector<AppState, string>((state: any) => state.general.text);
    const showReadFirstLevelAppealModal = useSelector<AppState, boolean>((state: any) => state.healthClaim.showReadFirstLevelAppealModal);
    const showReadSecondLevelAppealModal = useSelector<AppState, boolean>((state: any) => state.healthClaim.showReadSecondLevelAppealModal);
    const customer = useSelector<AppState, User | null>((state: any) => state.auth.customer);
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[authActionTypes.GET_CUSTOMER]);

    const emptyReadFirstLevelAppeal = () => {
        props.readFirstLevelAppealReset();
        props.onGetCustomer(user!.customerId);
    };

    const emptyReadSecondLevelAppeal = () => {
        props.readSecondLevelAppealReset();
        props.onGetCustomer(user!.customerId);
    };

    const readNotification = (notification: Notification) => {
        if (notification.appealLevel === '1') {
            openFirstLevelAppeal(notification.queryString);
        }

        if (notification.appealLevel === '2') {
            openSecondLevelAppeal(notification.queryString);
        }
    }

    const openFirstLevelAppeal = (code: string) => {
        props.onRead(code);
        props.triggerMark(code);
    };

    const openSecondLevelAppeal = (code: string) => {
        props.onReadSecondLevel(code);
        props.triggerMark(code);
    };

    return (
        <div>
            <ReadFirstLevelAppealModal show={showReadFirstLevelAppealModal} header={header} text={text} onClose={emptyReadFirstLevelAppeal} onHide={() => { return; }} />
            <ReadSecondLevelAppealModal show={showReadSecondLevelAppealModal} header={header} text={text} onClose={emptyReadSecondLevelAppeal} onHide={() => { return; }} />
            {isLoading ? <Loading />
                : <>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card card-custom bgi-no-repeat card-stretch gutter-b" style={{ backgroundPosition: 'right top', backgroundSize: '30% auto', backgroundImage: 'url(/media/dashboard/abstract-2.svg)' }}>
                                <div className="card-body">
                                    <div className="card-title font-weight-bold text-muted text-hover-primary font-size-h5">
                                        SIGAL
                                    </div>
                                    <div className="font-weight-bold text-success mt-9 mb-5  font-size-h1">
                                        Mireseerdhet ne Portalin e Klientit
                                    </div>
                                    <div className="text-dark-75 font-weight-bolder font-size-h6 m-0">
                                        Shpejt dhe me lehtësi, nga komoditeti i shtëpisë ose zyrës tuaj, realizoni policën tuaj të sigurimeve online. Shijoni blerjet tuaja. Vetëm i siguruari mund të jetë i lumtur          </div>
                                    <Link
                                        to={{ pathname: "https://youtu.be/LhLfXMqyANc" }}
                                        target="_blank"
                                        className="text-primary font-size-h5 font-weight-bolder text-hover-primary pt-5 pull-right"
                                        id="kt_login_forgot"
                                    >
                                        Tutorial: Portali i Klientit - Kërkesat për Rimbursim Shëndetësore
                                    </Link>
                                    {!!customer && customer.notifications && customer.notifications.length > 0 &&
                                        customer.notifications.map((notification: Notification, index: number) => {
                                            return <div key={index}>
                                                <Link to="" onClick={() => readNotification(notification)} className="text-primary font-size-h5 font-weight-bolder text-hover-primary pt-5" rel="noopener noreferrer">{notification.message}</Link>
                                            </div>;
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-4">
                            <div className="card card-custom bgi-no-repeat gutter-b"
                                style={{ height: '300px', backgroundPosition: 'calc(90% + 0.5rem) calc(90% + 0.5rem)', backgroundSize: '32% auto', backgroundImage: 'url(/media/dashboard/family.svg)' }}>
                                <div className="card-body">
                                    <a href="#" target='_blank' rel="noopener noreferrer" className="text-dark-75 font-weight-bolder line-height-lg mb-5 font-size-h3">Produktet Online</a>
                                    <div className="empty-row"></div>
                                    <div> <a href="private-health-policy" target='_self' rel="noopener noreferrer" className="text-dark-50  line-height-lg  font-size-h6">&gt; Sigurimi shëndetësor</a></div>
                                    <div> <a href="travel-health-policy" target='_self' rel="noopener noreferrer" className="text-dark-50 line-height-lg  font-size-h6">&gt; Shëndeti në udhëtim</a></div>
                                    <div> <a href="term-life-policy" target='_self' rel="noopener noreferrer" className="text-dark-50 line-height-lg  font-size-h6">&gt; Sigurimi i Jetës</a></div>
                                    <div> <a href="property-policy" target='_self' rel="noopener noreferrer" className="text-dark-50  line-height-lg  font-size-h6">&gt; Sigurimi i Pronës</a></div>
                                    <div> <a href="foreigner-policy" target='_self' rel="noopener noreferrer" className="text-dark-50  line-height-lg  font-size-h6">&gt; Sigurimi për lejeqëndrim</a></div>
                                    <div> <a href="kasko-policy" target='_self' rel="noopener noreferrer" className="text-dark-50 line-height-lg  font-size-h6">&gt; Sigurimi Kasko</a></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="card card-custom  bgi-no-repeat gutter-b" style={{ height: '300px', backgroundPosition: 'calc(90% + 0.5rem) calc(90% + 0.5rem)', backgroundSize: '30% auto', backgroundImage: 'url(/media/dashboard/car--md.svg)' }}>
                                <div className="card-body d-flex flex-column p-0">
                                    <div className="flex-grow-1 text-right card-spacer pb-0">
                                        <div className="font-weight-bolder font-size-h2"><a className="text-dark-75" href="https://sigal-ks.com/" target='_blank' rel="noopener noreferrer">
                                            SIGURIMI TPL+</a></div>
                                        <div className="empty-row"></div>
                                        <div className="text-dark-50  font-weight-bold"> Ky sigurim vlenë vetëm për territorin e Maqedonisë.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="card card-custom bgi-no-repeat bgi-size-cover gutter-b" style={{ height: '300px', color: '#55adb5', backgroundPosition: 'calc(90% + 0rem) calc(90% + 0.5rem)', backgroundSize: '30% auto', backgroundImage: 'url(/media/dashboard/shield.svg)' }}>
                                <div className="card-body">
                                    <a href="#" className="text-dark-75 text-hover-primary font-weight-bolder font-size-h3">   SIGURIMI KUFITAR       </a>
                                    <div className="empty-row"></div>
                                    <div className="text-dark-50  font-weight-bold"> Është i obliguar me ligj për të gjitha mjetet motorike me tabela të huaja, të cilat hyjnë në territorin e Republikës së Kosovës.    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-8">
                            <div className="card card-custom bgi-no-repeat bgi-size-cover gutter-b " style={{ height: '250px', backgroundPosition: 'calc(100% + 0rem) calc(100% + 0.5rem)', backgroundSize: '55% auto', backgroundImage: 'url(/media/dashboard/abstract-4.svg)', backgroundColor: '#005ca9' }}>
                                <div className="card-body d-flex">
                                    <div className="d-flex py-5 flex-column align-items-start flex-grow-1">
                                        <div className="flex-grow-1">
                                            <a href="https://sigal-ks.com/" target='_blank' rel="noopener noreferrer" className="text-white font-weight-bolder font-size-h3">SIGAL UNIQA</a>
                                            <p className="text-white opacity-75 font-weight-bold mt-3">

                                                Rr. Ukshin Hoti, No. 19, Peyton, Cat. 4, Prishtina, Kosovo <br />
                                                Tel: +381 38 240 241 <br />
                                                <a href="mailto:info@uniqa-ks.com" target='_blank' rel="noopener noreferrer" className="font-weight-bold font-size-lg pt-2">info@uniqa-ks.com</a> <br />
                                                <a href="https://sigal-ks.com/" target='_blank' rel="noopener noreferrer" className="font-weight-bold font-size-lg pt-2">sigal-ks.com/</a> <br />


                                            </p>
                                        </div>
                                        <a href='https://sigal-ks.com/' target='_blank' rel="noopener noreferrer" className="btn btn-link btn-link-white font-weight-bold">&gt; Kontakte në Drejtorinë e Përgjithshme </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4">
                            <div className="card card-custom bgi-no-repeat bgi-size-cover gutter-b" style={{ height: '250px', backgroundColor: '#7fadd4' }}>
                                <div className="card-body">
                                    <a href="#" className="text-dark-75 text-hover-primary font-weight-bolder font-size-h3">   Keni Pyetje?        </a>
                                    <div className="empty-row"></div>
                                    <div>  +381 (0) 38 240 241    </div>
                                    <div > Hëne - Premte: 8.00 - 16.00   </div>
                                    <div > <a href="mailto:info@uniqa-ks.com" target='_blank' rel="noopener noreferrer" className="text-dark-75 font-weight-bold font-size-lg pt-2">info@uniqa-ks.com</a>     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div >
    );
}

const mapDispatchToProps = (dispatch: Dispatch<healthClaimActionTypes.ReadFirstLevelAppealAction
    | generalActionTypes.HideModalAction | healthClaimActionTypes.ReadFirstLevelAppealResetAction
    | healthClaimActionTypes.ReadSecondLevelAppealAction | healthClaimActionTypes.ReadSecondLevelAppealResetAction
    | GetCustomerAction | healthClaimActionTypes.MarkAppealAsReadAction>) => ({
        closeModal: () => { dispatch(hideModal()); },
        onRead: (code: string) => {
            dispatch(readFirstLevelAppeal(code));
        },
        readFirstLevelAppealReset: () => { dispatch(readFirstLevelAppealReset()); },
        onGetCustomer: (customerId: string) => {
            dispatch(getCustomer(customerId));
        },
        triggerMark: (code: string) => {
            dispatch(markAppealAsRead(code));
        },
        onReadSecondLevel: (code: string) => {
            dispatch(readSecondLevelAppeal(code));
        },
        readSecondLevelAppealReset: () => { dispatch(readSecondLevelAppealReset()); },
    });

export default connect(null, mapDispatchToProps)(Home);