import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { AppState } from '../../../../redux/reducers/rootReducer';
import { Loading } from '../../../Layout/Loading';
import InputFieldMain from '../../../Custom/InputFieldMain';
import SelectField from '../../../Custom/SelectField';
import DatePickerFieldInline from '../../../Custom/DatePickerFieldInline';
import { Nomen } from '../../../../utils';
import { Gender } from '../../../../utils/nomen';
import * as nomenActionTypes from '../../../../redux/actionTypes/nomenActionTypes';
import { DropzoneArea } from 'material-ui-dropzone';
import { makeStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';
import store from "../../../../redux/configureStore";
import { prepareThPolicyForQuotation } from '.././../../../utils/policyTypes';
import { useFormikWizard } from 'formik-wizard';
import * as thActions from "../../../../redux/actionCreators/thPolicyActionCreators";
import { ThInsQuotation } from '../../../../utils/policyTypes';
import imageCompression from 'browser-image-compression';
import { User } from '../../../../redux/types';
import * as authActions from "../../../../redux/actionCreators/authActionCreators";
import moment from 'moment';

type Props = {
    data?: any | null;
    index: number;
    enableDelete: boolean;
    removeTraveller: (travellerIndex: any) => void;
    dropkey: number;
}

const Traveller = (props: Props) => {
    const { setFieldValue, values, setValues }: any = useFormikContext();
    const { values: wizardValues }: any = useFormikWizard();
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[nomenActionTypes.GET_PLACES]);
    const places = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Places);
    const user = useSelector<AppState, User | null>((state: any) => state.auth.user);

    const chosenTHQuotation = useSelector<AppState, ThInsQuotation[] | null>((state: any) => state.policy.chosenTHQuotation);
    const thPackageQuotation = chosenTHQuotation ? chosenTHQuotation[0] : null;

    const handleClick: React.MouseEventHandler<
        HTMLButtonElement | HTMLAnchorElement
    > = e => {
        if (!!props.removeTraveller && props.index >= 0) props.removeTraveller(props.index);
    }

    const recalculateQuotation = (date: any) => {
        const value = moment(date, "DD/MM/yyyy");
        if (value
            && value.isValid()
            && value.isBefore(moment())
            && moment().diff(value, 'years') <= 64) {
            var currentValues = { ...values };
            currentValues.travellers[props.index].birthDate = value.toDate();
            setValues(currentValues);

            const state = store.getState();
            const customerId = state.auth.user?.customerId;

            wizardValues.thMain.insuredPersonsList = values.travellers;

            const packageId = thPackageQuotation ? thPackageQuotation.packageId : '';

            const thPolicy = prepareThPolicyForQuotation(customerId, wizardValues.thMain, packageId);
            store.dispatch(thActions.getPackageTHQuotation(thPolicy));
        }
    }

    const handleSavePassport = (files: any) => {
        if (files.length === 0)
            return;

        var passportPhotos = files;

        var options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: false
        };

        let compressed: File[] = [];

        passportPhotos.forEach((passportPhoto: File) => {
            imageCompression(passportPhoto, options)
                .then(function (compressedBlob) {
                    var compressedPassport = new File([compressedBlob], compressedBlob.name, { lastModified: new Date().getTime(), type: compressedBlob.type })
                    compressed.push(compressedPassport);

                    setFieldValue(PassportPhoto, compressed);
                })
                .catch(function (error) {
                });
        });
    }

    const handleDeletePassport = () => {
        setFieldValue(PassportPhoto, []);
    }

    const checkProfile = (value: any) => {
        var currentValues = { ...values };

        if (props.index !== currentValues.travellers.length - 1)
            return;

        const customerId = user?.customerId;

        if (!customerId) {
            store.dispatch(authActions.hasProfile(value));
        }
    };


    const ClientNo: string = `travellers[${props.index}].clientNo`;
    const Title: string = `travellers[${props.index}].title`;
    const LastName: string = `travellers[${props.index}].lastName`;
    const Sex: string = `travellers[${props.index}].sex`;
    const Address: string = `travellers[${props.index}].address`;
    const Place: string = `travellers[${props.index}].place`;
    const Email: string = `travellers[${props.index}].email`;
    const Celphone: string = `travellers[${props.index}].celphone`;
    const BirthDate: string = `travellers[${props.index}].birthDate`;
    const Passport: string = `travellers[${props.index}].passport`;
    const PassportPhoto: any = `travellers[${props.index}].passportPhoto`;

    const useStyles = makeStyles(() => ({
        dropZone: {
            minHeight: '60px',
        },
        previewContainer: {
            container: 'true',
            width: '100%',
            height: '100%',
        },
        preview: {
            height: '100%',
            xs: '12',
        }
    }));

    const classes = useStyles();

    return (<>
        {isLoading
            ? <Loading />
            :
            <>
                <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    {props.index !== -1 && !!props.removeTraveller && props.enableDelete
                        && <div className="row">
                            <div className="col-xl-9"></div>
                            <div className="col-xl-3">
                                <a>
                                    <i className="flaticon2-rubbish-bin-delete-button text-muted icon-1x pull-right"
                                        onClick={handleClick} title="Hiq"></i>
                                </a>
                            </div>
                        </div>}
                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name={ClientNo} label="Numri personal" placeholder="Numri personal" maxLength={16} newvalue={props.data?.clientNo || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name={Title} label="Emri" placeholder="Emri" newvalue={props.data?.title || ''} inline capitalized />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name={LastName} label="Mbiemri" placeholder="Mbiemri" newvalue={props.data?.lastName || ''} inline capitalized />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <SelectField name={Sex} label="Gjinia" items={Gender} newvalue={props.data?.sex || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name={Address} label="Adresa" placeholder="Adresa" newvalue={props.data?.address || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <SelectField name={Place} label="Vendi" items={places} newvalue={props.data?.place || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name={Email} blur={checkProfile} label="Email" placeholder="Email" newvalue={props.data?.email || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name={Celphone} label="Nr. telefonit" placeholder="Nr. telefonit" newvalue={props.data?.celphone || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name={Passport} label="Pasaporta" placeholder="Pasaporta" newvalue={props.data?.passport || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <DatePickerFieldInline name={BirthDate} type="text" placeholder="Datëlindja" blur={recalculateQuotation} newvalue={props.data?.birthDate || ''} />
                        </div>
                    </div>

                    <div key={props.index > 0 ? props.dropkey + props.index * 100 : props.dropkey} className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                        <DropzoneArea
                            dropzoneClass={classes.dropZone}
                            dropzoneText='Foto e pasaportës'
                            onChange={handleSavePassport}
                            onDelete={handleDeletePassport}
                            acceptedFiles={['image/jpeg', 'image/png', 'image/jpeg']}
                            filesLimit={1}
                            maxFileSize={10485760}
                            showPreviews={true}
                            showPreviewsInDropzone={false}
                            showFileNamesInPreview={true}
                            showFileNames={true}
                            initialFiles={props.data?.passportPhoto || []}
                            previewGridClasses={{
                                container: classes.previewContainer,
                                item: classes.preview
                            }}
                            getPreviewIcon={(file: any, classes: any) => {
                                return (
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M14 0C16.7614 0 19 2.23858 19 5V17C19 20.866 15.866 24 12 24C8.13401 24 5 20.866 5 17V9H7V17C7 19.7614 9.23858 22 12 22C14.7614 22 17 19.7614 17 17V5C17 3.34315 15.6569 2 14 2C12.3431 2 11 3.34315 11 5V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V6H15V17C15 18.6569 13.6569 20 12 20C10.3431 20 9 18.6569 9 17V5C9 2.23858 11.2386 0 14 0Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                );
                            }}
                        />
                        <hr />
                    </div>
                </div>
            </>
        }
    </>);
}

export default connect()(Traveller);
