import React, { useMemo } from "react";
import objectPath from "object-path";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { HeaderMenuLeft } from "./HeaderMenuLeft";
import { HeaderMenuRight } from "./HeaderMenuRight";

export function HeaderMenuWrapper() {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            config: uiService.config,
            ktMenuClasses: uiService.getClasses("header_menu", true),
            rootArrowEnabled: objectPath.get(
                uiService.config,
                "header.menu.self.root-arrow"
            ),
            menuDesktopToggle: objectPath.get(uiService.config, "header.menu.desktop.toggle"),
            headerMenuAttributes: uiService.getAttributes("header_menu"),
            headerSelfTheme: objectPath.get(uiService.config, "header.self.theme"),
            ulClasses: uiService.getClasses("header_menu_nav", true),
            disabledAsideSelfDisplay: true
        };
    }, [uiService]);
    const getHeaderLogo = () => {
        let result = "sigal.png";
        if (layoutProps.headerSelfTheme && layoutProps.headerSelfTheme !== "dark") {
            result = "sigal.png";
        }
        return toAbsoluteUrl(`/media/logos/${result}`);
    };

    return <>
        {/*begin::Header Menu Wrapper*/}
        <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper_left">
            {/*begin::Header Menu*/}
            <HeaderMenuLeft layoutProps={layoutProps} />
            {/*end::Header Menu*/}
        </div>

        {/* Logo */}
        {layoutProps.disabledAsideSelfDisplay && (
            <>
                {/*begin::Header Logo*/}
                <div className="header-logo">
                    <Link to="/dashboard">
                        <img alt="logo" src={getHeaderLogo()} />
                    </Link>
                </div>
                {/*end::Header Logo*/}
            </>
        )}

        <div className="header-menu-wrapper header-menu-wrapper-right" id="kt_header_menu_wrapper_right">
            {/*begin::Header Menu*/}
            <HeaderMenuRight layoutProps={layoutProps} />
            {/*end::Header Menu*/}
        </div>
        {/*Header Menu Wrapper*/}
    </>
}
