import * as React from 'react';
import { connect } from 'react-redux';
import InputFieldMain from '../../Custom/InputFieldMain';
import RadioButtonGroupFieldMain from '../../Custom/RadioButtonGroupFieldMain';
import { useFormikContext } from 'formik';
import CheckboxField from "../../Custom/CheckboxField";
import PersonalDataConsent from "../../../../src/InsuranceConditions/POLITIKA-E-PRIVATESISE-SIGAL.pdf";
import { useFormikWizard } from 'formik-wizard';

const PropertyPolicyMain = () => {
    const { values, setValues }: any = useFormikContext();
    const { values: wizardValues }: any = useFormikWizard();

    const showCoupon = values.couponAnswer && values.couponAnswer === '1';

    const couponChanged = (value: any) => {
        if (value === '1')
            return;

        var currentValues = { ...values };
        currentValues.coupon = '';
        setValues(currentValues);
    }

    const objectTypeChanged = (value: any) => {
        if (value === '087') {
            wizardValues.insuredPerson.tipKind = '2';
            wizardValues.insuredPerson.clientNo = '';
            wizardValues.insuredPerson.lastName = '';
            wizardValues.insuredPerson.sex = '';
            wizardValues.insuredPerson.birthDate = '';
        } else {
            wizardValues.insuredPerson.tipKind = '3';
            wizardValues.insuredPerson.nipt = '';
        }
    }

    return (
        <>
            <div className="wizardTitle">Detajet e pronës</div>
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                <div className="mb-10 font-weight-bold text-dark"></div>
                <div className="row">
                    <div className="col-xl-12 addSpace">
                        <label className="inlineLbl">Lloji i objektit të sigurimit</label>
                        <RadioButtonGroupFieldMain name="objectType" type="radio" placeholder="" label="" change={objectTypeChanged} items={[{ id: '122', value: 'Banesë' }, { id: '123', value: 'Shtëpi' }, { id: '087', value: 'Dyqane/Lokale' }]} newvalue={values?.objectType || ''} inline />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 addSpace">
                        <InputFieldMain type="number" name="area" label="Metër katror" placeholder="Metër katror" newvalue={values?.area || ''} inline />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 addSpace">
                        <InputFieldMain type="number" name="buildYear" label="Viti i ndërtimit" placeholder="Viti i ndërtimit" newvalue={values?.buildYear || ''} inline />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 addSpace">
                        <InputFieldMain type="text" name="objectAddress" label="Adresa e objektit qe sigurohet" newvalue={values?.objectAddress || ''} placeholder="Adresa e objektit qe sigurohet" inline />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 addSpace">
                        <label className="inlineLbl">Nëse dëshironi të siguroni pajisje shtesë zgjidhni vlerën</label>
                        <RadioButtonGroupFieldMain name="inventoryInsuredAmount" type="radio" placeholder="" label="" items={[{ id: '15000', value: '15.000 €' }, { id: '20000', value: '20.000 €' }, { id: '25000', value: '25.000 €' }, { id: 'Jo', value: 'Jo' }]} newvalue={values?.insuredAmmounts || ''} inline />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 ">
                        <label className="inlineLbl">A keni një kupon?</label>
                        <RadioButtonGroupFieldMain change={couponChanged} name="couponAnswer" type="radio" placeholder="" label="" items={[{ id: '1', value: 'Po' }, { id: '2', value: 'Jo' }]} newvalue={values?.couponAnswer || ''} inline />
                    </div>
                </div>
                {showCoupon && <div className="row">
                    <div className="col-xl-12 addSpace">
                        <InputFieldMain type="text" name="coupon" label="Kupon" placeholder="Kupon" inline maxLength={30} />
                    </div>
                </div>}
                <hr />
                <div className="row">
                    <div className="col-xl-12 addSpace">
                        <CheckboxField name="personalDataConsent" type="checkbox" label="Konfirmoj se kam lexuar dhe jam pajtuar e përcaktimet, kushtet, autorizimin dhe jap pëlqimin për marrjen dhe mbrojtjen e të dhënave personale" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 addSpace">
                        <a href={PersonalDataConsent} target="_blank">Politika e privatesisë Sigal Uniqa Group Austria Kosovë</a>
                    </div>
                </div>
            </div>
        </>
    );
}
export default connect()(PropertyPolicyMain);
