import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import DatePickerFieldInline from '../../../Custom/DatePickerFieldInline';
import { AppState } from '../../../../redux/reducers/rootReducer';
import SelectField from '../../../Custom/SelectField';
import { Nomen } from '../../../../utils';
import InputFieldMain from '../../../Custom/InputFieldMain';
import * as nomenActionTypes from "../../../../redux/actionTypes/nomenActionTypes";
import { Loading } from '../../../Layout/Loading';
import { useFormikContext } from 'formik';
import CustomSelectField from "../../../Custom/CustomSelectField";
import CheckboxField from "../../../Custom/CheckboxField";
import PersonalDataConsent from "../../../../../src/InsuranceConditions/POLITIKA-E-PRIVATESISE-SIGAL.pdf";
import RadioButtonGroupFieldMain from "../../../Custom/RadioButtonGroupFieldMain";

const TravelHealthPolicyMain = () => {
    const { values, setValues }: any = useFormikContext();
    const { setFieldValue } = useFormikContext();

    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[nomenActionTypes.GET_TH_ZONE]);
    const thZones = useSelector<AppState, Nomen[]>((state: any) => state.nomen.THZones);
    const countries = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Countries);
    const dispatch = useDispatch();
    const showCoupon = values.couponAnswer && values.couponAnswer === '1';

    useEffect(() => {
        if (values.zone != null && values.zone != undefined && values.zone != "") {
            dispatch({ type: nomenActionTypes.GET_COUNTRIES, zone: values.zone });
        }
    }, [values.zone]);

    const zoneChanged = (value: any) => {
        setFieldValue("country", "");
    }

    const couponChanged = (value: any) => {
        if (value === '1')
            return;

        var currentValues = { ...values };
        currentValues.coupon = '';
        setValues(currentValues);
    }

    return (<>
        {isLoading
            ? <Loading />
            :
            <>
                <div className="wizardTitle">Detajet e udhëtimit</div>
                <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    <div className="mb-10 font-weight-bold text-dark"></div>
                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <DatePickerFieldInline name="startDate" type="text" placeholder="Fillon" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <DatePickerFieldInline name="endDate" type="text" placeholder="Mbaron" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <CustomSelectField name="zone" label="Zona e udhëtimit" items={thZones} change={zoneChanged} inline />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <SelectField name="country" label="Vendi" items={countries} inline />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="insuredPersons" label="Numri i anetareve te familjes qe deshironi ti siguroni" placeholder="Numri i anetareve te familjes qe deshironi ti siguroni" inline />
                        </div>
                    </div>
                    {/*<div className="row">*/}
                    {/*    <div className="col-xl-12 addSpace">*/}
                    {/*        <label className="inlineLbl">A keni një kupon?</label>*/}
                    {/*        <RadioButtonGroupFieldMain change={couponChanged} name="couponAnswer" type="radio" placeholder="" label="" items={[{ id: '1', value: 'Po' }, { id: '2', value: 'Jo' }]} newvalue={values?.couponAnswer || ''} inline />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {showCoupon && <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="coupon" label="Kupon" placeholder="Kupon" inline maxLength={30} />
                        </div>
                    </div>}

                    <hr />
                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <CheckboxField name="personalDataConsent" type="checkbox" label="Konfirmoj se kam lexuar dhe jam pajtuar e përcaktimet, kushtet, autorizimin dhe jap pëlqimin për marrjen dhe mbrojtjen e të dhënave personale" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <a href={PersonalDataConsent} target="_blank">Politika e privatesisë Sigal Uniqa Group Austria Kosovë</a>
                        </div>
                    </div>
                </div>
            </>}
    </>
    );
}
export default connect()(TravelHealthPolicyMain);
