import React, { useEffect } from 'react';
import { Modal, ModalProps } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import { Dispatch } from "redux";
import { connect, useSelector } from "react-redux";
import { loginUser } from "../../redux/actionCreators/authActionCreators";
import * as actionTypes from "../../redux/actionTypes/authActionTypes";
import { Link } from "react-router-dom";
import { Loading2 } from "../Layout/Loading2";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import InputFieldMain from "../Custom/InputFieldMain";
import { AppState } from '../../redux/reducers/rootReducer';
import { continueWithoutAccount, showLoginModalReset } from '../../redux/actionCreators/generalActionCreators';
import * as generalActionTypes from "../../redux/actionTypes/generalActionTypes";

interface FormValues {
    email: string;
    password: string;
}

const initialValues = {
    email: "",
    password: "",
};

type Props = {
    onClose: () => void;
    onLogin(email: string, password: string): void;
    onContinueWithoutAccount(): void;
    isLoading: boolean;
    error: null | Error | string;
}
const LoginModal = (props: FormikProps<FormValues> & Props & ModalProps) => {
    const email = useSelector<AppState, string | null>((state: any) => state.general.autoLoginEmail);

    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .email("Format I gabuar I email-it")
            .min(3, "Minimumi 3 simbole")
            .max(50, "Maksimumi 50 simbole")
            .required('E domosdoshme'),
        password: Yup.string()
            .min(3, "Minimumi 3 simbole")
            .max(50, "Maksimumi 50 simbole")
            .required('E domosdoshme'),
    });

    const handleSubmit = async (values: any) => {
        props.onLogin(values.email,
            values.password);
    };

    useEffect(() => {
        handleSubmit(initialValues);
    }, []);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton style={{ justifyContent: "center" }}>
                <Modal.Title id="contained-modal-title-vcenter">
                    <Link to="/" className="login-logo">
                        <img
                            alt="Logo"
                            className="max-h-70px"
                            src={toAbsoluteUrl("/media/logos/sigal.png")}
                        />
                    </Link>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.isLoading ? <Loading2 />
                    :
                    <>
                        <div className="t-centered">
                            Ekziston një përdorues i regjistruar me këtë adresë email-i. <br />
                            Identifikohuni për tu lidhur në llogarinë tuaj te Portali i Klientit
                        </div>
                        <hr />
                        <Formik
                            enableReinitialize
                            initialValues={initialValues}
                            validationSchema={LoginSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ getFieldProps, handleSubmit }) => (
                                <form onSubmit={(e: any) => {
                                    handleSubmit(e);
                                }}>

                                    {/* email */}
                                    <div className="form-group addSpace">
                                        <InputFieldMain  {...getFieldProps("email")} type="text" label="Email" placeholder="Email" inline newvalue={email ? email : ''} disabled={true} />
                                    </div>

                                    {/* password */}
                                    <div className="form-group addSpace">
                                        <InputFieldMain {...getFieldProps("password")} type="password" label="Fjalëkalim" placeholder="Fjalëkalim" inline />
                                    </div>
                                    <hr />

                                    <div className="pb-lg-0 pb-5 t-centered">
                                        <button className="btn btn-wiz btn-default font-weight-bolder font-size-h6 px-6 py-3 my-3 mr-3 modalBtn" onClick={props.onClose}>Anulo</button>
                                        <button
                                            id="kt_login_form_modal_submit_button"
                                            type="submit"
                                            disabled={props.isLoading}
                                            className={`btn btn-wiz font-weight-bolder font-size-h6 px-6 py-3 my-3 mr-3 modalBtn`}
                                        >
                                            <span>Hyr</span>
                                            {props.isLoading && <span className="ml-3 spinner spinner-white"></span>}
                                        </button>

                                        <button className="btn btn-wiz font-weight-bolder font-size-h6 px-6 py-3 my-3 mr-3 modalBtn" onClick={() => props.onContinueWithoutAccount()}>Blej Direkt</button>

                                    </div>
                                </form >
                            )}
                        </Formik>
                    </>
                }
            </Modal.Body>
        </Modal>
    )
};

const mapDispatchToProps = (dispatch: Dispatch<actionTypes.LoginUserAction
    | generalActionTypes.ContinueWithoutAccountAction
    | generalActionTypes.ShowLoginModalResetAction>) => ({
        onLogin: (email: string, password: string) => {
            dispatch(loginUser(email, password, true));
        },
        onContinueWithoutAccount: () => {
            dispatch(continueWithoutAccount());
            dispatch(showLoginModalReset());
        }
    });

const mapStateToProps = (state: any) => ({
    isLoading: state.isLoading[actionTypes.LOGIN_USER],
    error: state.error[actionTypes.LOGIN_USER]
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);