import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { useSelector } from "react-redux";

export function HeaderMobile() {
  const uiService = useHtmlClassService();
  const user = useSelector((state) => state.auth.user);

  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      asideMobileDisplay: objectPath.get(uiService.config, "aside.self.mobile"),
      headerMenuSelfDisplay:
        objectPath.get(uiService.config, "header.menu.self.display") === true,
      headerMobileCssClasses: uiService.getClasses("header_mobile", true),
      headerMobileAttributes: uiService.getAttributes("header_mobile")
    };
  }, [uiService]);

  return (
    <>
      {/*begin::Header Mobile*/}
      <div
        id="kt_header_mobile"
        className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`}
        {...layoutProps.headerMobileAttributes}
      >
        {/*begin::Logo*/}
        <Link to="/">
          <img alt="logo" src={layoutProps.headerLogo} />
        </Link>
        {/*end::Logo*/}
        {!user && <Link
          to="/auth/login"
          className={`btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3`}
          id="kt_login_forgot"
        >
          Login
        </Link>}
        {/*begin::Toolbar*/}
        <div className="d-flex align-items-center">
          {layoutProps.asideMobileDisplay && (
            <>
              {/*begin::Aside Mobile Toggle*/}
              <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
                <span />
              </button>
              {/*end::Aside Mobile Toggle*/}
            </>
          )}
        </div>
        {/*end::Toolbar*/}
      </div>
      {/*end::Header Mobile*/}
    </>
  );
}
