import React, { useEffect } from 'react';
import { Modal, ModalProps } from "react-bootstrap";
import { Dispatch } from "redux";
import { connect, useSelector } from "react-redux";
import * as actionTypes from "../../redux/actionTypes/authActionTypes";
import { Link } from "react-router-dom";
import { Loading2 } from "../Layout/Loading2";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { continueWithoutAccount, showLoginModalReset, showRegistrationModal, continueWithoutAccountReset, showCreateAccountQuestionModalReset } from '../../redux/actionCreators/generalActionCreators';
import * as generalActionTypes from "../../redux/actionTypes/generalActionTypes";
import { AppState } from '../../redux/reducers/rootReducer';

type Props = {
    onClose: () => void;
    onCreateAccount(insuredPerson: any): void;
    onContinueWithoutAccount(): void;
    isLoading: boolean;
    error: null | Error | string;
}
const CreateAccountQuestionModal = (props: Props & ModalProps) => {
    const insuredPerson = useSelector<AppState, string | null>((state: any) => state.general.insuredPerson);

    const showRegistrationModal = () => {
        props.onCreateAccount(insuredPerson);
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton style={{ justifyContent: "center" }}>
                <Modal.Title id="contained-modal-title-vcenter">
                    <Link to="/" className="login-logo">
                        <img
                            alt="Logo"
                            className="max-h-70px"
                            src={toAbsoluteUrl("/media/logos/sigal.png")}
                        />
                    </Link>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.isLoading ? <Loading2 />
                    :
                    <>
                        <div className="t-centered">
                            <strong>Duke krijuar një llogari personale do të keni këto shërbime shtesë:</strong> <br />
                            Historia e blerjeve - Paraqitja e demeve Online - Kontrollimi i statusit të rimbursimeve dhe sherbimeve shtesë
                        </div>
                        <hr />
                        <div className="pb-lg-0 pb-5 t-centered">
                            <button className="btn btn-wiz btn-default font-weight-bolder font-size-h6 px-6 py-3 my-3 mr-3 modalBtn" onClick={props.onClose}>Anulo</button>
                            <button className="btn btn-wiz font-weight-bolder font-size-h6 px-6 py-3 my-3 mr-3 modalBtn" onClick={() => showRegistrationModal()}>Krijo Llogari + Blej</button>
                            <button className="btn btn-wiz font-weight-bolder font-size-h6 px-6 py-3 my-3 mr-3 modalBtn" onClick={() => props.onContinueWithoutAccount()}>Blej Direkt</button>
                        </div>
                    </>
                }
            </Modal.Body>
        </Modal>
    )
};

const mapDispatchToProps = (dispatch: Dispatch<actionTypes.LoginUserAction
    | generalActionTypes.ContinueWithoutAccountAction
    | generalActionTypes.ShowLoginModalResetAction
    | generalActionTypes.ShowRegistrationModalAction
    | generalActionTypes.ContinueWithoutAccountResetAction
    | generalActionTypes.ShowCreateAccountQuestionModalResetAction>) => ({
        onCreateAccount: (insuredPerson: any) => {
            dispatch(showRegistrationModal(insuredPerson));
            dispatch(continueWithoutAccountReset());
            dispatch(showCreateAccountQuestionModalReset());
        },
        onContinueWithoutAccount: () => {
            dispatch(continueWithoutAccount());
            dispatch(showLoginModalReset());
            dispatch(showCreateAccountQuestionModalReset());
        }
    });

const mapStateToProps = (state: any) => ({
    isLoading: state.isLoading[actionTypes.LOGIN_USER],
    error: state.error[actionTypes.LOGIN_USER]
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountQuestionModal);