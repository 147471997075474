import React from "react";
import { Button, Modal, ModalProps } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik, FormikProps } from "formik";
import { Dispatch } from "redux";
import { connect, useSelector } from "react-redux";
import { confirmVerificationCode, resendVerificationCode } from "../../../redux/actionCreators/authActionCreators";
import * as actionTypes from "../../../redux/actionTypes/authActionTypes";
import { Link } from "react-router-dom";
import { Loading2 } from "../../Layout/Loading2";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { AppState } from "../../../redux/reducers/rootReducer";
import { CheckProfileDto } from "../../../utils";
import { continueWithoutAccount, showVerificationModalReset } from '../../../redux/actionCreators/generalActionCreators';
import * as generalActionTypes from "../../../redux/actionTypes/generalActionTypes";

interface FormValues {
    verificationCode: string;
}

const initialValues = {
    verificationCode: "",
};

type Props = {
    onClose: () => void;
    onConfirmCode(verificationCode: string, email: string): void;
    isLoading: boolean;
    error: null | Error | string;
    onResendCode(email: string): void;
    onContinueWithoutAccount(): void;
}
const VerificationCodeModal = (props: FormikProps<FormValues> & Props & ModalProps) => {
    const checkProfile = useSelector<AppState, CheckProfileDto>((state: any) => state.auth.checkProfile);

    const { onConfirmCode, onResendCode } = props;

    const VerificationSchema = Yup.object().shape({
        verificationCode: Yup.string()
            .min(6, "Minimumi 6 simbole")
            .max(6, "Maksimumi 6 simbole")
            .required('E domosdoshme')
    });

    const formik = useFormik({
        initialValues,
        validationSchema: VerificationSchema,
        onSubmit: (values) => {
            if (checkProfile.hasProfile) {
                onConfirmCode(values.verificationCode, checkProfile.email);
            }
        }
    });

    const resendVerificationCode = () => {
        if (checkProfile.hasProfile) {
            onResendCode(checkProfile.email);
        }
    }

    const verificationCodeClass = 'form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0 '
        + (formik.touched.verificationCode && ((formik.errors.verificationCode && 'is-invalid') || (!formik.errors.verificationCode && 'is-valid')));

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton style={{ justifyContent: "center" }}>
                <Modal.Title id="contained-modal-title-vcenter">
                    <Link to="/" className="login-logo">
                        <img
                            alt="Logo"
                            className="max-h-70px"
                            src={toAbsoluteUrl("/media/logos/sigal.png")}
                        />
                    </Link>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.isLoading ? <Loading2 />
                    :
                    <>
                        <div className="t-centered">
                            Ne dërgojmë kodin e verifikimit në adresën tuaj të emailit. <br />
                            Për të verifikuar që kjo është adresa juaj e emailit, shkruani kodin e verifikimit më poshtë:
                        </div>
                        <hr />
                        <form
                            onSubmit={formik.handleSubmit}
                            className="form fv-plugins-bootstrap fv-plugins-framework"
                        >
                            <div className="form-group fv-plugins-icon-container">
                                <div>
                                    <label className="font-size-h6 text-dark inlineLbl">Kodi i verifikimit</label>
                                    <div className="inlineField">
                                        <input
                                            placeholder="Kodi i verifikimit"
                                            type="text"
                                            className={verificationCodeClass}
                                            {...formik.getFieldProps("verificationCode")}
                                        />
                                        {formik.touched.verificationCode && formik.errors.verificationCode ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{formik.errors.verificationCode}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>

                                <div>
                                    <a className="font-weight-bolder btn-resend-code" onClick={resendVerificationCode}> Ridërgo kodin e verifikimit </a>
                                </div>
                            </div>
                            <hr />
                            <div className="pb-lg-0 pb-5 t-centered">
                                <button className="btn btn-wiz btn-default font-weight-bolder font-size-h6 px-6 py-3 my-3 mr-3 modalBtn" onClick={props.onClose}>Anulo</button>
                                <button
                                    id="kt_verify_code_submit_button"
                                    type="submit"
                                    disabled={props.isLoading}
                                    className={`btn btn-wiz font-weight-bolder font-size-h6 px-6 py-3 my-3 mr-3 modalBtn`}
                                >
                                    <span>Konfirmo</span>
                                    {props.isLoading && <span className="ml-3 spinner spinner-white"></span>}
                                </button>

                                <button className="btn btn-wiz font-weight-bolder font-size-h6 px-6 py-3 my-3 mr-3 modalBtn" onClick={() => props.onContinueWithoutAccount()}>Blej Direkt</button>
                            </div>
                        </form>
                    </>
                }
            </Modal.Body>
        </Modal>
    )
};

const mapDispatchToProps = (dispatch: Dispatch<actionTypes.ConfirmVerificationCodeAction
    | actionTypes.ResendVerificationCodeAction
    | generalActionTypes.ContinueWithoutAccountAction
    | generalActionTypes.ShowVerificationModalResetAction>) => ({
        onConfirmCode: (verificationCode: string, email: string) => {
            dispatch(confirmVerificationCode(verificationCode, email));
        },
        onResendCode: (email: string) => {
            dispatch(resendVerificationCode(email));
        },
        onContinueWithoutAccount: () => {
            dispatch(continueWithoutAccount());
            dispatch(showVerificationModalReset());
        }
    });

const mapStateToProps = (state: any) => ({
    isLoading: state.isLoading[actionTypes.CONFIRM_VERIFICATION_CODE] || state.isLoading[actionTypes.RESEND_VERIFICATION_CODE],
    error: state.error[actionTypes.CONFIRM_VERIFICATION_CODE] || state.error[actionTypes.RESEND_VERIFICATION_CODE]
});

export default connect(mapStateToProps, mapDispatchToProps)(VerificationCodeModal);